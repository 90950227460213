const prod_config = {
    API_URL: 'https://api.staging.consulnet.net/admin',
    Tableoptions: {
        paginationSize: 4,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        disablePageTitle: true,
        custom: true,
        dataSize: 0,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: 'All', value: 0
            }
        ] // A numeric array is also available. the purpose of above example is custom the text
    },
    UploadDir: `${process.env.PUBLIC_URL}/assets/uploads`,
};
const dev_config = {
    API_URL: 'http://localhost:3334/admin',
    Tableoptions: {
        paginationSize: 4,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        disablePageTitle: true,
        custom: true,
        dataSize: 0,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '25', value: 25
        }, {
            text: 'All', value: 0
        }] // A numeric array is also available. the purpose of above example is custom the text
    },
    UploadDir: `${process.env.PUBLIC_URL}/assets/uploads/`,
};

export const config = process.env.NODE_ENV === 'development' ? dev_config : prod_config;